// src/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.tixquant.com';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    console.log('Token in interceptor:', token);  // Add this line
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Authorization header:', config.headers['Authorization']);  // Add this line
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the token and redirect to login
      localStorage.removeItem('authToken');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const fetchData = async (endpoint, options = {}) => {
  try {
    const response = await api.request({
      url: endpoint,
      ...options,
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

// Add other specific API calls as needed

export default api;