import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Stored token:', token);
      if (token) {
        try {
          console.log('Sending token:', token);  // Add this line
          const userData = await fetchData('/user');
          console.log('User data:', userData);
          setUser({ isLoggedIn: true, ...userData });
        } catch (error) {
          console.error('Error fetching user data:', error);
          console.error('Error response:', error.response);  // Add this line
          if (error.response && error.response.status === 401) {
            console.log('Unauthorized, clearing token');
            localStorage.removeItem('authToken');
            setUser(null);
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await fetchData('/login', {
        method: 'POST',
        data: { email, password },
      });
      
      if (response.success) {
        const token = response.session.access_token;
        localStorage.setItem('authToken', token);
        console.log('Token stored after login:', token);  // Add this line
        setUser({ isLoggedIn: true, ...response.user });
        navigate('/dashboard');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await fetchData('/logout', { method: 'POST' });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('authToken');
      setUser(null);
      navigate('/login');
    }
  };

  const value = {
    user,
    login,
    logout,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};