import React, { useState, useEffect } from 'react';
import { fetchData } from '../api';
import { LineChart, BarChart } from '@mui/x-charts';
import { 
  Grid, 
  Paper, 
  Card, 
  CardContent, 
  Typography, 
  Box 
} from '@mui/material';
import * as Icons from '@mui/icons-material';

// Dashboard component: Displays various charts and data visualizations
const Dashboard = () => {
  // State variables to store different types of data and UI states
  const [aggregateData, setAggregateData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [companies, setCompanies] = useState({});
  const [dateRange, setDateRange] = useState('all');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [aggregateChartData, setAggregateChartData] = useState([]);
  const [yoyChangeData, setYoyChangeData] = useState([]);
  const [marketShareData, setMarketShareData] = useState([]);

  // Effect hook to fetch initial data when component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch various data from API endpoints
        const aggregateResponse = await fetchData('/aggregate/');
        setAggregateData(aggregateResponse || []);

        const salesResponse = await fetchData('/sales/');
        setSalesData(salesResponse || []);
        
        const companyResponse = await fetchData('/companies/');
        
        // Create a map of company data with random colors if not provided
        const companyMap = {};
        (companyResponse || []).forEach(company => {
          companyMap[company.id] = { 
            name: company.name, 
            color: company.color || getRandomColor() 
          };
        });
        setCompanies(companyMap);

        const marketShareResponse = await fetchData('/market-share/');
        setMarketShareData(marketShareResponse || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Effect hook to update chart data when date range or data changes
  useEffect(() => {
    const filteredAggregateData = filterDataByDateRange(aggregateData);
    const filteredSalesData = filterDataByDateRange(salesData);
    
    const newChartData = prepareChartData(filteredSalesData);
    setChartData(newChartData);
    
    // Prepare aggregate chart data
    const newAggregateChartData = filteredAggregateData.map(item => ({
      date: item.date,
      primary_total: item.primary_total,
      secondary_total: item.secondary_total
    }));
    setAggregateChartData(newAggregateChartData);
    
    const newYoyChangeData = calculateYoyChangeData(filteredSalesData);
    setYoyChangeData(newYoyChangeData);
  }, [dateRange, aggregateData, salesData, companies]);

  // Helper function to generate random color
  const getRandomColor = () => {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  };

  // TODO: Implement date range filtering logic
  const filterDataByDateRange = (data) => {
    // Implement your date range filtering logic here
    return data;
  };

  // TODO: Implement chart data preparation logic
  const prepareChartData = (data) => {
    // Implement your chart data preparation logic here
    return data;
  };

  // TODO: Implement Year-over-Year change calculation logic
  const calculateYoyChangeData = (data) => {
    // Implement your YoY change calculation logic here
    return data;
  };

  // Normalize market share data to percentages
  const normalizeMarketShareData = (data, companies) => {
    return data.map(item => {
      const total = companies.reduce((sum, company) => sum + (item[company] || 0), 0);
      const normalizedItem = { ...item };
      companies.forEach(company => {
        normalizedItem[company] = total > 0 ? (item[company] || 0) / total : 0;
      });
      return normalizedItem;
    });
  };

  // Prepare market share data for chart visualization
  const prepareMarketShareData = (data, companies) => {
    const normalizedData = normalizeMarketShareData(data, companies);
    return companies.map(company => ({
      label: company.replace('_mkt', '').toUpperCase(),
      data: normalizedData.map(item => item[company])
    }));
  };

  // MarketShareChart component: Renders a bar chart for market share data
  const MarketShareChart = ({ title, companies, data }) => {
    const normalizedData = normalizeMarketShareData(data, companies);
    
    return (
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          {data.length > 0 ? (
            <BarChart
              xAxis={[{ 
                data: normalizedData.map(item => item.date),
                scaleType: 'band',
                tickLabelStyle: { angle: 45, textAnchor: 'start', fontSize: 12 }
              }]}
              series={prepareMarketShareData(data, companies)}
              width={500}
              height={300}
              margin={{ top: 20, right: 100, bottom: 70, left: 70 }}
              slotProps={{
                legend: {
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                  itemMarkWidth: 10,
                  itemMarkHeight: 10,
                  markGap: 5,
                  itemGap: 5,
                },
              }}
              yAxis={[{ 
                label: 'Market Share',
                labelStyle: { fontSize: 12 },
                max: 1,
                tickFormat: (value) => `${(value * 100).toFixed(0)}%`,
              }]}
            />
          ) : (
            <Typography>No market share data available</Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  // Calculate the last figure and its percentage change
  const getLastFigureWithChange = (data, key) => {
    if (data.length < 2) return { lastFigure: 0, percentChange: 0 };
    const lastFigure = data[data.length - 1][key];
    const previousFigure = data[data.length - 2][key];
    const percentChange = ((lastFigure - previousFigure) / previousFigure) * 100;
    return { lastFigure, percentChange };
  };

  // Format currency values
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  // Render loading or error states
  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  // Main render function for the Dashboard
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Primary Exchange Credit Card Sales Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Weekly Primary Exchange Credit Card Sales
              </Typography>
              {aggregateChartData.length > 0 && (
                <>
                  {/* Display last figure and percentage change */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4">
                      {formatCurrency(getLastFigureWithChange(aggregateChartData, 'primary_total').lastFigure)}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                      {getLastFigureWithChange(aggregateChartData, 'primary_total').percentChange > 0 ? (
                        <Icons.ArrowUpward color="success" />
                      ) : (
                        <Icons.ArrowDownward color="error" />
                      )}
                      <Typography 
                        color={getLastFigureWithChange(aggregateChartData, 'primary_total').percentChange > 0 ? 'success.main' : 'error.main'}
                      >
                        {getLastFigureWithChange(aggregateChartData, 'primary_total').percentChange.toFixed(2)}%
                      </Typography>
                    </Box>
                  </Box>
                  {/* Line chart for primary sales */}
                  <LineChart
                    xAxis={[{ 
                      data: aggregateChartData.map(item => item.date), 
                      scaleType: 'band',
                      tickLabelStyle: { angle: 45, textAnchor: 'start', fontSize: 12 }
                    }]}
                    series={[
                      {
                        data: aggregateChartData.map(item => item.primary_total),
                        area: true,
                        color: '#8884d8',
                        curve: 'linear',
                        showMark: false,
                      },
                    ]}
                    width={500}
                    height={300}
                    margin={{ top: 20, right: 50, bottom: 70, left: 70 }}
                    yAxis={[{ 
                      label: 'Sales ($)',
                      labelStyle: { fontSize: 12 },
                    }]}
                  />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Secondary Exchange Credit Card Sales Chart */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Weekly Secondary Exchange Credit Card Sales
              </Typography>
              {aggregateChartData.length > 0 && (
                <>
                  {/* Display last figure and percentage change */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4">
                      {formatCurrency(getLastFigureWithChange(aggregateChartData, 'secondary_total').lastFigure)}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                      {getLastFigureWithChange(aggregateChartData, 'secondary_total').percentChange > 0 ? (
                        <Icons.ArrowUpward color="success" />
                      ) : (
                        <Icons.ArrowDownward color="error" />
                      )}
                      <Typography 
                        color={getLastFigureWithChange(aggregateChartData, 'secondary_total').percentChange > 0 ? 'success.main' : 'error.main'}
                      >
                        {getLastFigureWithChange(aggregateChartData, 'secondary_total').percentChange.toFixed(2)}%
                      </Typography>
                    </Box>
                  </Box>
                  {/* Line chart for secondary sales */}
                  <LineChart
                    xAxis={[{ 
                      data: aggregateChartData.map(item => item.date), 
                      scaleType: 'band',
                      tickLabelStyle: { angle: 45, textAnchor: 'start', fontSize: 12 }
                    }]}
                    series={[
                      {
                        data: aggregateChartData.map(item => item.secondary_total),
                        area: true,
                        color: '#82ca9d',
                        curve: 'linear',
                        showMark: false,
                      },
                    ]}
                    width={500}
                    height={300}
                    margin={{ top: 20, right: 50, bottom: 70, left: 70 }}
                    yAxis={[{ 
                      label: 'Sales ($)',
                      labelStyle: { fontSize: 12 },
                    }]}
                  />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Primary Ticketing Market Share Chart */}
        <Grid item xs={12} md={6}>
          <MarketShareChart 
            title="Primary Ticketing Market Share"
            companies={['tm_mkt', 'eb_mkt', 'axs_mkt']}
            data={marketShareData}
          />
        </Grid>

        {/* Secondary Ticketing Market Share Chart */}
        <Grid item xs={12} md={6}>
          <MarketShareChart 
            title="Secondary Ticketing Market Share"
            companies={['viagogo_mkt', 'vv_mkt', 'seatgeek_mkt']}
            data={marketShareData}
          />
        </Grid>

        {/* Add more Grid items here for other charts */}

      </Grid>
    </Box>
  );
};

export default Dashboard;